import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.page.html',
  styleUrls: ['./confirm-modal.page.scss'],
})
export class ConfirmModalPage implements OnInit {

  @Input() title: string;
  @Input() statement: string; 
  @Input() confirm_btn: string;
  @Input() not_confirm_btn: any;
  @Input() leader_out: boolean;
  @Input() account_delete: boolean;


  leader_out_reason: string
  accountPassword: string
  constructor(private modalController: ModalController,
    public sanitizer: DomSanitizer) { }

  ngOnInit() {
    console.log(this.statement)
  }
  updateLeaderOutReason(event){
    this.leader_out_reason = event.detail.value
  }
  passwordEntered(event){
    this.accountPassword = event.detail.value
  }
  async dismiss(covidPassed) {
    await this.modalController.dismiss({
      'dismissed': true,
      'covid': covidPassed,
      'leader_out_reason':this.leader_out_reason,
      'accountPassword':this.accountPassword
    });
  }
}
